@import "../theme.scss";

.memories {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 1.5em;
  background: $dark-charcoal;

  padding: 1em 1em 3em 1em;
  overflow: hidden;
  color: #ffffff;

  .box-outline {
    border-image-source: repeating-linear-gradient(
      30deg,
      rgba(255, 255, 255, 0.45),
      rgba(255, 255, 255, 0.45) 10px,
      rgba(255, 255, 255, 0.25) 10px,
      rgba(255, 255, 255, 0.25) 20px
    );
  }

  .notification {
    flex: 0 0 15%;
    font-size: clamp(0.8rem, 1.2vw, 2rem);
    font-family: "Lato", sans-serif;
    text-align: center;
  }
}
