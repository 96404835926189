.our-story {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 1.5em;
  background-color: #ffffff;
  // height: 500px;
  overflow: hidden;
  user-select: none;

  // .wrapper {
  //   background-color: aqua;
  //   .sunflower-art {
  //     width: 50%;
  //     display: block;
  //     margin-left: auto;
  //     margin-right: auto;
  //   }
  // }

  .floral-banner {
    width: 40%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .story-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;

    .text-section {
      flex: 7;
      padding: 5%;
      font-size: clamp(0.8rem, 1.2vw, 3rem);
      margin: auto;

      @media screen and (max-width: 450px) {
        flex: 1;
        padding: 8%;
      }
    }

    .image-section {
      flex: 5;
      padding: 5%;
      font-size: clamp(0.8rem, 1.2vw, 3rem);
      align-self: center;

      @media screen and (max-width: 450px) {
        padding: 12%;
        flex: 1;
      }
    }
  }

  .story-image-left {
    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  }

  .story-image-right {
    @media screen and (max-width: 450px) {
      flex-direction: column-reverse;
    }
  }

  .image-frame {
    width: 90%;
    background-color: #fbfbfb;
    box-sizing: border-box;
    padding: 4% 4% 15% 4%;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
    margin: 0 auto;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
