@import "../theme.scss";

.footer {
  width: 100%;
  max-width: 1200px;

  margin: 0 auto;
  background: $smoky-black;
  padding: 2em 1em 2em 1em;
  overflow: hidden;
  color: #ffffff;

  .footer-image {
    width: 20%;
    height: auto;
    margin: 0 auto 30px auto;
    @media screen and (max-width: 450px) {
      width: 40%;
    }
  }
  .footer-text-1 {
    font-size: clamp(0.8rem, 1.2vw, 2rem);
    font-family: "Lato", sans-serif;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
  }

  .footer-text-2 {
    font-size: clamp(2.2rem, 3vw, 4rem);
    font-family: "Passions Conflict", cursive;
    text-align: center;
    color: rgba(255, 255, 255, 0.75);
  }
}
