@import "../theme.scss";

.venue {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 1.5em;
  background: $wenge;
  padding: 1em 1em 3em 1em;
  overflow: hidden;
  color: #ffffff;

  .box-outline {
    border-image-source: repeating-linear-gradient(
      30deg,
      rgba(255, 255, 255, 0.45),
      rgba(255, 255, 255, 0.45) 10px,
      rgba(255, 255, 255, 0.25) 10px,
      rgba(255, 255, 255, 0.25) 20px
    );
  }
}

.google-maps-component {
  display: grid;
  grid-column-gap: 4rem;
  grid-row-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: fit-content(10px);

  .map-widget {
    background-color: #ffffff;
    margin: 0px 20px;
    box-sizing: border-box;
  }

  .map-description {
    display: flex;
    flex-direction: column;

    .title {
      font-size: clamp(1.8rem, 2.3vw, 4rem);
      font-family: "Passions Conflict", cursive;
      text-align: left;

      @media screen and (max-width: 450px) {
        text-align: center;
      }
    }

    .text-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 1em 1.5em;
      box-sizing: border-box;

      .subtitle {
        flex: 0 0 15%;
        font-size: clamp(0.8rem, 1.2vw, 2rem);
        font-family: "Lato", sans-serif;
        text-align: right;
        font-weight: bold;
        text-transform: uppercase;
      }

      .text {
        flex: 1;
        font-size: clamp(0.8rem, 1.2vw, 2rem);
        font-family: "Lato", sans-serif;
        text-align: left;
        padding-left: 1.4em;
      }
    }
  }
}

.mapouter {
  aspect-ratio: 4 / 3;
  position: relative;
  text-align: right;
  width: 100%;
  height: auto;
}

.gmap_canvas {
  aspect-ratio: 4 / 3;
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: auto;
}

.gmap_iframe {
  aspect-ratio: 4 / 3;
  width: 100% !important;
  height: auto !important;
}
