.video-header {
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  user-select: none;
  min-height: 50vh;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(90%);
  }

  .box-outline {
    border: none;
    border-bottom: rgba(255, 255, 255, 0.35) 2px solid;
    display: inline-block;
    padding: 20px;
    margin-bottom: 0.8em;
  }

  .text-wrapper {
    position: absolute;
    bottom: 5%;
    left: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    z-index: 10;

    .text-name {
      font-size: clamp(2.5rem, 4.5vw, 4rem);
      font-family: "Oooh Baby", cursive;
      margin-bottom: 10px;
    }

    .text-title {
      font-size: clamp(1.8rem, 4vw, 2.5rem);
      line-height: clamp(1.4rem, 3.4vw, 2.5rem);
      font-family: "Passions Conflict", cursive;
    }
  }
}
