@import "./theme.scss";

.App {
  height: 100%;

  background-color: #e5e5f7;
  opacity: 1;
  background-image: linear-gradient(
      135deg,
      rgba(194, 194, 194, 0.4) 25%,
      transparent 25%
    ),
    linear-gradient(225deg, rgba(194, 194, 194, 0.4) 25%, transparent 25%),
    linear-gradient(45deg, rgba(194, 194, 194, 0.4) 25%, transparent 25%),
    linear-gradient(315deg, rgba(194, 194, 194, 0.4) 25%, #e5e5f7 25%);
  background-position: 25px 0, 25px 0, 0 0, 0 0;
  background-size: 50px 50px;
  background-repeat: repeat;
}

.section-title {
  font-size: clamp(2rem, 3vw, 4rem);
  font-family: $section-font;
  text-align: center;
}

.section-title-with-underline {
  font-size: clamp(1.8rem, 3vw, 4rem);
  font-family: $section-font;
  text-align: center;
  margin-bottom: 20px;

  .box-outline {
    border: none;
    display: inline-block;
    padding: 10px 30px;
    margin-bottom: 0.8em;
    border-style: solid;
    border-width: 5px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-image-slice: 1;
  }
}
