@import "../theme.scss";

.rsvp {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 1.5em;
  background: $raisin-black;
  padding: 1em;
  overflow: hidden;
  color: #ffffff;

  .box-outline {
    border-image-source: repeating-linear-gradient(
      30deg,
      rgba(255, 255, 255, 0.45),
      rgba(255, 255, 255, 0.45) 10px,
      rgba(255, 255, 255, 0.25) 10px,
      rgba(255, 255, 255, 0.25) 20px
    );
  }

  .rsvp-form {
    padding: 1em 10px;
    width: 80%;
    min-width: 300px;
    margin: auto;
  }
}
